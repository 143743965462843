'use strict';

module.exports = {
    showMore: function () {
        $('.container').on('click', '.show-more-blogs button', function (e) {
            e.stopPropagation();
            var $target = $(event.target);
            var showMoreUrl = $(this).data('url');
            e.preventDefault();
            $.spinner().start();

            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success: function (response) {
                    $target.closest('.blog-grid-footer').replaceWith(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    }
};
